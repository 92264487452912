import React from 'react'
import Layout from '../../layouts/layout'

class Contact extends React.Component {
  render () {
    return (
<Layout>
        <header className="masthead contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>Contact Us</h1>
                  <span className="subheading">Have questions? Want to make a post here? Want to know our favorite color? Message us!</span>
                  <a href="mailto:frewin.christopher@gmail.com">Email!</a>
                </div>
              </div>
            </div>
          </div>
        </header>
        
</Layout>
    );
  }
}

export default Contact;
